input[type="text"],
input[type="email"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="time"],
input[type="date"],
input[type="search"],
input, textarea {
  margin: 0;
  width: 100%;
  height: 60px;
  font-weight: 700;
  color:var(--heading-color);
  font-size: 16px;
  border-radius: 50px;
  background-color: transparent;
  padding: 15px 24px !important;
  border: none!important;
  transition: all 0.4s;
  border: 1px solid var(--heading-color)!important;
}
// input:focus, 
// textarea:focus{
//   border: 1px solid var(--accent-color)!important;
// }
textarea {
  height: 200px;
  resize: none;
  border-radius: 20px;
  @include max(767px) {
    height: 100px;
  }
}

input::-webkit-input-placeholder, 
textarea::-webkit-input-placeholder{ /* WebKit, Blink, Edge */
    color:    #666;
    opacity: 1;
}
input:-moz-placeholder, 
textarea:-moz-placeholder{ /* Mozilla Firefox 4 to 18 */
   color:    #666;
   opacity:  1;
}
input::-moz-placeholder, 
textarea::-moz-placeholder{ /* Mozilla Firefox 19+ */
   color:    #666;
   opacity:  1;
}
input:-ms-input-placeholder, 
textarea:-ms-input-placeholder{ /* Internet Explorer 10-11 */
   color:    #666;
   opacity: 1;
}
input::-ms-input-placeholder, 
textarea::-ms-input-placeholder{ /* Microsoft Edge */
   color:    #666;
   opacity: 1;
}

input::placeholder, 
textarea::placeholder{ /* Most modern browsers support this now. */
   color:    #666;
   opacity: 1;
}

.aximo-form{
   &-wrap{
      @include max(991px) {
         margin-top: 50px;
       }
      h4{
         margin-bottom: 24px;
         color: var(--white-smoke)
      }
   }
   
   &-field{
      margin-bottom: 24px;
      input, textarea{
      color: #ccc;
      border: 1px solid #ccc!important;
      &:focus, 
      &:focus{
         border: 1px solid var(--accent-color)!important;
      }
      &::-webkit-input-placeholder, 
      &::-webkit-input-placeholder{ /* WebKit, Blink, Edge */
         color:    #ccc;
         opacity: 1;
      }
      &:-moz-placeholder, 
      &:-moz-placeholder{ /* Mozilla Firefox 4 to 18 */
         color:    #ccc;
         opacity:  1;
      }
      &::-moz-placeholder, 
      &::-moz-placeholder{ /* Mozilla Firefox 19+ */
         color:    #ccc;
         opacity:  1;
      }
      &:-ms-input-placeholder, 
      &:-ms-input-placeholder{ /* Internet Explorer 10-11 */
         color:    #ccc;
         opacity: 1;
      }
      &::-ms-input-placeholder, 
      &::-ms-input-placeholder{ /* Microsoft Edge */
         color:    #ccc;
         opacity: 1;
      }

      &::placeholder, 
      &::placeholder{ /* Most modern browsers support this now. */
         color:    #ccc;
         opacity: 1;
      }
      }

      textarea{
         height: 150px;
         @include max(767px) {
            height: 100px;
         }
      }
      
   }
}

#aximo-submit-btn{
   width: 100%;
   height: 60px;
   font-size: 16px;
   font-weight: 700;
   border-radius: 50px;
   border: 2px solid var(--dark-bg);
   background: var(--accent-bg);
   box-shadow: 0px 4px 0px 0px #FDFDE1;
   transition: all 0.4s;
   padding-left: 40px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   &:hover{
      box-shadow: none;
   }
   span{
      width: 50px;
      height: 50px;
      display: flex;
      margin-right: 3px;
      align-items: center;
      border-radius: 100px;
      justify-content: center;
      background-color: var(--dark-bg);
   }
}
// end v1 footer form

.aximo-form{
   &-wrap2{
      padding: 40px;
      border-radius: 10px;
      background-color: var(--light-bg3);
      @include max(991px) {
         margin-top: 40px;
      }
      @include max(767px) {
         padding: 30px;
      }
      h3{
         margin-bottom: 10px;
         font-family: $heading-font3;
      }
      p{
         font-size: 20px;
         @include max(767px) {
            font-size: 18px;
         }
      }
   }
   &-field2{
      margin-bottom: 24px;
      input,
      textarea{
         font-weight: 600;
         padding: 15px 0!important;
         border-radius: 0!important;
         border: none!important;
         color: var(--heading-color);
         border-bottom: 1px solid #ccc!important;
         &::-webkit-input-placeholder, 
         &::-webkit-input-placeholder{ /* WebKit, Blink, Edge */
            color:    var(--heading-color);
            opacity: 1;
         }
         &:-moz-placeholder, 
         &:-moz-placeholder{ /* Mozilla Firefox 4 to 18 */
            color:    var(--heading-color);
            opacity:  1;
         }
         &::-moz-placeholder, 
         &::-moz-placeholder{ /* Mozilla Firefox 19+ */
            color:    var(--heading-color);
            opacity:  1;
         }
         &:-ms-input-placeholder, 
         &:-ms-input-placeholder{ /* Internet Explorer 10-11 */
            color:    var(--heading-color);
            opacity: 1;
         }
         &::-ms-input-placeholder, 
         &::-ms-input-placeholder{ /* Microsoft Edge */
            color:    var(--heading-color);
            opacity: 1;
         }

         &::placeholder, 
         &::placeholder{ /* Most modern browsers support this now. */
            color:    var(--heading-color);
            opacity: 1;
         }
      }
      textarea{
         height: 80px;
      }
   }
   
}

button#aximo-submit-btn2{
   width: 100%;
   height: 60px;
   line-height: 60px;
   padding: 0 15px;
   font-weight: 700;
   text-align: center;
   border-radius: 50px;
   margin-top: 16px;
   overflow: hidden;
   transition: all 0.4s;
   color: var(--heading-color);
   background-color: var( --yellow-bg);
   .aximo-label-up {
      display:block;
      height:100%;
      position:relative;
      top:0%;
      transition:0.3s;
    }
   &:hover .aximo-label-up{
      top:-100%;
   }
  
}
// end v3

// end all form field

// start subscription
// v2
.aximo-subscription{
   input{
      height: 50px;
      border-radius: 3px;
      margin-bottom: 12px;
      border: 2px solid #999!important;
      &:focus{
         border: 2px solid var(--orange-bg)!important;
       }
       
&::-webkit-input-placeholder{ /* WebKit, Blink, Edge */
   color:    var(--heading-color2);
   opacity: 1;
}
&:-moz-placeholder{ /* Mozilla Firefox 4 to 18 */
  color:    var(--heading-color2);
  opacity:  1;
}
&::-moz-placeholder{ /* Mozilla Firefox 19+ */
  color:    var(--heading-color2);
  opacity:  1;
}
&:-ms-input-placeholder{ /* Internet Explorer 10-11 */
  color:    var(--heading-color2);
  opacity: 1;
}
&::-ms-input-placeholder{ /* Microsoft Edge */
  color:    var(--heading-color2);
  opacity: 1;
}

&::placeholder{ /* Most modern browsers support this now. */
  color:    var(--heading-color2);
  opacity: 1;
}

   }
   
}

#aximo-subscription-btn{
   width: 160px;
   height: 50px;
   font-weight: 700;
   font-size: 16px;
   line-height: 50px;
   border-radius: 3px;
   border: none!important;
   padding: 0!important;
   color: var(--light-bg2);
   background-color: var(--orange-bg);
   transition: all 0.4s;
   overflow: hidden;
   .aximo-label-up {
      display:block;
      height:100%;
      position:relative;
      top:0%;
      transition:0.3s;
    }
   &:hover .aximo-label-up{
      top:-100%;
   }
   @include max(767px) {
      width: 100%;
    }
}

// v4

.aximo-subscription2{
   position: relative;
   input{
      height: 60px;
      font-size: 16px;
      font-weight: 700;
      width: calc(100% - 220px);
      border: 2px solid var(--light-color3)!important;
      &:focus{
         border: 2px solid var(--orange-bg2)!important;
       }
       @include max(767px) {
         width: 100%;
         margin-bottom: 20px;
       }
       
&::-webkit-input-placeholder{ /* WebKit, Blink, Edge */
   color:    var(--light-color3);
   opacity: 1;
}
&:-moz-placeholder{ /* Mozilla Firefox 4 to 18 */
  color:    var(--light-color3);
  opacity:  1;
}
&::-moz-placeholder{ /* Mozilla Firefox 19+ */
  color:    var(--light-color3);
  opacity:  1;
}
&:-ms-input-placeholder{ /* Internet Explorer 10-11 */
  color:    var(--light-color3);
  opacity: 1;
}
&::-ms-input-placeholder{ /* Microsoft Edge */
  color:    var(--light-color3);
  opacity: 1;
}

&::placeholder{ /* Most modern browsers support this now. */
  color:    var(--light-color3);
  opacity: 1;
}

   }
   
}

#aximo-subscription-btn2{
   top: 0;
   right: 0;
   width: 196px;
   height: 60px;
   font-weight: 700;
   font-size: 16px;
   position: absolute;
   border-radius: 50px;
   border: none!important;
   padding: 0!important;
   color: var(--light-bg4);
   background-color: var(--blue-bg);
   transition: all 0.4s;
   &:hover{
      background-color: var(--orange-bg2);
   }
   .aximo-label-up {
      display:block;
      height:100%;
      position:relative;
      top:0%;
      transition:0.3s;
    }
   &:hover .aximo-label-up{
      top:-100%;
   }
   @include max(767px) {
      width: 100%;
    }
   @include max(767px) {
      width: 100%;
      position: static;
    }
}




// blog single

.comment-form{
   margin-bottom: 24px;
   input,
   textarea{
      font-weight: 700;
      border: none !important;
      padding: 15px 0!important;
      border-radius: 0!important;
      color: var(--heading-color);
      border-bottom: 1px solid #999!important;
      &::-webkit-input-placeholder, 
      &::-webkit-input-placeholder{ /* WebKit, Blink, Edge */
         color:    #999;
         opacity: 1;
      }
      &:-moz-placeholder, 
      &:-moz-placeholder{ /* Mozilla Firefox 4 to 18 */
         color:    #999;
         opacity:  1;
      }
      &::-moz-placeholder, 
      &::-moz-placeholder{ /* Mozilla Firefox 19+ */
         color:    #999;
         opacity:  1;
      }
      &:-ms-input-placeholder, 
      &:-ms-input-placeholder{ /* Internet Explorer 10-11 */
         color:    #999;
         opacity: 1;
      }
      &::-ms-input-placeholder, 
      &::-ms-input-placeholder{ /* Microsoft Edge */
         color:    #999;
         opacity: 1;
      }

      &::placeholder, 
      &::placeholder{ /* Most modern browsers support this now. */
         color:    #999;
         opacity: 1;
      }
   }
   textarea{
      height: 150px;
      @include max(767px) {
         height: 100px;
      }
   }
}
   
#comment-btn{
   width: 180px;
   height: 60px;
   font-weight: 700;
   font-size: 16px;
   line-height: 50px;
   border-radius: 3px;
   border: none!important;
   padding: 0!important;
   color: var(--light-bg5);
   background-color: var(--dark-bg);
   transition: all 0.4s;
   overflow: hidden;
   border-radius: 50px;
   margin-top: 26px;
   &:hover{
      color: var(--heading-color);
      background-color: var(--accent-bg);
   }
   @include max(767px) {
      width: 100%;
    }
}
