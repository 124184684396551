.aximo-logo-section{
  padding: 25px 0;
  text-align: center;
  background-color: var(--dark-bg);
}
.aximo-account-title {
  text-align: center;
  margin-bottom: 45px;
}
.aximo-account-checkbox-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
a.forgot-password{
  font-size: 16px;
  color: var(--heading-color);
}
.aximo-account{
  &-wrap{
    padding: 40px;
    max-width: 856px;
    border-radius: 10px;
    margin: 0 auto;
    border: 1px solid var(--heading-color);
  }
  &-field{
    margin-bottom: 24px;
    label{
      font-weight: 700;
      display: block;
      margin-bottom: 10px;
    }
    input{
      font-weight: 400;
    }
  }
  &-checkbox{
    input{
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
    }
    label{
      position: relative;
      font-size: 16px;
      display: flex;
      align-items: center;
      a{
        color: var(--body-color);
        font-weight: 600;
        margin: 0 4px;
      }
      &:before{
        content:'';
        -webkit-appearance: none;
        background-color: transparent;
        border: 1.5px solid #7F8995;
        padding: 7px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 10px;
        border-radius: 3px;
      }
    }
  }
}

.aximo-account-checkbox input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 9px;
  left: 6px;
  width: 5px;
  height: 9px;
  border: solid var(--dark-bg);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

button#aximo-account-btn {
  height: 60px;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  border-radius: 50px;
  transition: all 0.4s;
  color: var(--white-color);
  margin: 50px 0;
  background-color: var(--dark-bg);
  &:hover{
    color: var(--heading-color);
    background-color: var(--accent-bg);
  }
}

.aximo-or {
  z-index: 0;
  line-height: 1;
  position: relative;
  text-align: center;
  margin-bottom: 40px;
  p{
    line-height: 1;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
    padding: 0 12px;
    display: inline-block;
    text-align: center;
    background-color: var(--light-bg1);
  }
  &::before{
    content: "";
    left: 0;
    top: 9px;
    width: 100%;
    height: 1px;
    z-index: -1;
    position: absolute;
    background-color: #E6E6E6;
  }
}

a.aximo-connect-login {
  height: 60px;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  border-radius: 50px;
  line-height: 60px;
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  justify-content: center;
  color: var(--heading-color);
  border: 1px solid var(--dark-bg);
  img{
    margin-right: 15px;
  }
}

.aximo-account-bottom{
  margin-top: 40px;
  text-align: center;
  p{
    font-size: 16px;
  }
  a{
    font-weight: 600;
    color: var(--body-color);
  }
}

// 404
.aximo-errors {
  &-wrap{
    padding: 200px 0 130px;
    text-align: center;
    @include max(991px) {
      padding: 170px 0 100px;
    }
    @include max(767px) {
      padding: 150px 0 80px;
    }
  }
  &-thumb{
    margin-bottom: 80px;
    @include max(991px) {
      margin-bottom: 60px;
    }
    @include max(767px) {
      margin-bottom: 40px;
    }
  }
  &-title{
    margin-bottom: 50px;
    @include max(767px) {
      margin-bottom: 40px;
    }
  }
  
}

.aximo-errors-btn{
  border-radius: 50px;
  width: 290px;
  padding: 13px 10px 13px 24px;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  position: relative;
  justify-content: space-between;
  color: #FFFDF8!important;
  background-color: var(--dark-bg);
  transition: all 0.4s;
  margin: 0 auto;
  span{
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    right: 5px;
    top: 50%;
    font-size: 24px;
    color: var(--white-smoke);
    transform: translateY(-50%);
    background-color: var(--accent-bg);
  }
}