.aximo-content-section{
  padding: 130px 0 160px;
  @include max(991px) {
    padding: 100px 0;
  }
  @include max(991px) {
    padding: 80px 0;
  }
}

.aximo-instagram{

  &-thumb{
    position: relative;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    &::before{
      content: "";
      left: 0;
      top: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      transition: all 0.4s;
      background-color: var(--orange-bg2);
    }
    &:hover:before{
      opacity: 1;
    }
    &:hover .aximo-instagram-data{
      top: 50%;
      opacity: 1;
      visibility: visible;
    }
  }
  &-data{
    left: 50%;
    top: 55%;
    opacity: 0;
    visibility: hidden;
    text-align: center;
    position: absolute;
    transition: all 0.4s;
    transform: translate(-50%, -50%);
    i{
      font-size: 38px;
      display: block;
      margin-bottom: 25px;
      color: var(--light-color3);
    }
    h3{
      
      font-family: $heading-font4;
      a{
        color: var(--light-color3);
      }
      @include max(1199px) {
        font-size: 24px;
      }
    }
  }
}