.aximo-cta{
  &-section{
    background-color: var(--heading-color2);
  }
  &-wrap{
    text-align: center;
    max-width: 815px;
    margin: 0 auto;
    @include max(1199px) { 
      max-width: 750px;
    }
    h2{
      font-size: 80px;
      margin-bottom: 40px;
      color: var(--light-color1);
      font-family: $heading-font2;
      @include max(1199px) { 
        font-size: 60px;
      }
      @include max(767px) { 
        font-size: 40px;
      }
    }
  }
}

.aximo-cta-shape1 {
  position: absolute;
  bottom: 21%;
  left: 10%;
  animation: float-left-right 6s ease-in-out infinite;
  img{
    @include max(1500px) {
      width: 230px;
    }
    @include max(991px) {
      display: none;
    }
  }
}

.aximo-cta-shape2 {
  top: -70px;
  right: 140px;
  position: absolute;
  -webkit-animation: rotate-animation 8s infinite linear;
        -moz-animation: rotate-animation 8s infinite linear;
        -o-animation: rotate-animation 8s infinite linear;
        animation: rotate-animation 8s infinite linear;
  @include max(991px) {
    display: none;
  }
}


.aximo-cta2{
  &-section{
    border-radius: 20px;
    overflow: hidden;
    position: relative;
  }
  &-wrap{
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
    @include max(1199px) { 
      max-width: 600px;
    }
    h2{
      margin-bottom: 24px;
      color: var(--light-color4);
      font-family: $heading-font5;
    }
    p{
      font-size: 21px;
      color: var(--light-color4);
      max-width: 740px;
      margin: 0 auto 50px;
    }
  }
}
.aximo-cta-shape3 {
  position: absolute;
  top: -15px;
  left: -18px;
  @include max(991px) {
    display: none;
  }
  img{
    @include max(1650px) {
      width: 200px;
    }
  }
}