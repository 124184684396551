


// .aximo-spinner-box {
//   width: 100%;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: fixed;
//   z-index: 9999;
//   top: 0;
//   left: 0;
//   background-color: var(--body-color);
// }

// .aximo-configure-border-1 {
//   width: 80px;
//   height: 80px;
//   padding: 3px;
//   position: absolute;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: var(--orange-color2);
//   animation: configure-clockwise 3s ease-in-out 0s infinite alternate;
// }

// .aximo-configure-border-2 {
//   width: 80px;
//   height: 80px;
//   padding: 3px;
//   left: -80px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: var(--yellow-bg);
//   transform: rotate(45deg);
//   animation: configure-xclockwise 3s ease-in-out 0s infinite alternate;
// }

// .aximo-configure-core {
//   width: 100%;
//   height: 100%;
//   background-color: #1d2630;
// }

// @keyframes configure-clockwise {
//   0% {
//     transform: rotate(0);
//   }
//   25% {
//     transform: rotate(80deg);
//   }
//   50% {
//     transform: rotate(180deg);
//   }
//   75% {
//     transform: rotate(270deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

// @keyframes configure-xclockwise {
//   0% {
//     transform: rotate(45deg);
//   }
//   25% {
//     transform: rotate(-45deg);
//   }
//   50% {
//     transform: rotate(-135deg);
//   }
//   75% {
//     transform: rotate(-225deg);
//   }
//   100% {
//     transform: rotate(-315deg);
//   }
// }




.aximo-loading-dots {
  text-align: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dark-bg);
  
  &--dot {
    animation: dot-keyframes 1.5s infinite ease-in-out;
    background-color: var(--accent-bg);
    border-radius: 10px;
    display: inline-block;
    height: 12px;
    width: 12px;
    margin: 0 7px;
    
    &:nth-child(2) {
      animation-delay: .5s;
    }
    
    &:nth-child(3) {
      animation-delay: 1s;
    }
  }
}

@keyframes dot-keyframes {
  0% {
    opacity: .4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: .4;
    transform: scale(1, 1);
  }
}


// \



.aximo-preloader-wrap {
  position: fixed;
  z-index: 1222;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: var(--dark-bg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.aximo-preloader {
  display: inline-block;
  position: relative;
  width: 74px;
  height: 100px;
  align-items: center;
  justify-content: center;
}

.aximo-preloader div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--accent-color);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.aximo-preloader div:nth-child(1) {
  left: 8px;
  animation: aximo-preloader1 0.6s infinite;
}

.aximo-preloader div:nth-child(2) {
  left: 8px;
  animation: aximo-preloader2 0.6s infinite;
}

.aximo-preloader div:nth-child(3) {
  left: 32px;
  animation: aximo-preloader2 0.6s infinite;
}

.aximo-preloader div:nth-child(4) {
  left: 56px;
  animation: aximo-preloader3 0.6s infinite;
}

@keyframes aximo-preloader1 {
  0% {
      transform: scale(0);
  }

  100% {
      transform: scale(1);
  }
}

@keyframes aximo-preloader3 {
  0% {
      transform: scale(1);
  }

  100% {
      transform: scale(0);
  }
}

@keyframes aximo-preloader2 {
  0% {
      transform: translate(0, 0);
  }

  100% {
      transform: translate(24px, 0);
  }
}