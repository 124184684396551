// fonts variable
$body-font: 'Inter', sans-serif!default;
$heading-font: 'Syne', sans-serif !default;
$heading-font2: 'ClashGroteskSemibold' !default;
$heading-font3: 'Familjen Grotesk', sans-serif !default;
$heading-font4: 'Arimo', sans-serif !default;
$heading-font5: 'Libre Baskerville', serif !default;
$heading-font6:  "Playfair Display", serif !default;
$heading-font7:  "Bricolage Grotesque", sans-serif !default;

// color variable
:root {
  --heading-color: #000;
  --heading-color2: #1B1B1B;
  --heading-color3: #191931;
  --heading-color4: #221F1A;
  --heading-color5: #2C2C2C;
  --heading-color6: #0B1040;
  --body-color: #0C0C0C;
  --accent-color: #BFF747;
  --white-smoke: #FDFDE1;
  --white-color: #ffffff;
  --orange-color: #FF4612;
  --orange-color2: #FF8064;
  --corn-color: #F4B905;
  --green-color: #073D30;
  --green-color2: #02854C;
  --light-color1: #F6F5EF;
  --light-color2: #FEFCFB;
  --light-color3: #FFF7EA;
  --light-color4: #FFFCF2;
  --light-color5: #F3EDE8;
  --light-color6: #F8FCDD;
  --dark-bg: #000000;
  --dark-bg2: #2C2C2C;
  --dark-bg3: #0B1040;
  --white-bg: #ffffff;
  --orange-bg: #FF4612;
  --orange-bg2: #FF8064;
  --accent-bg: #BFF747;
  --yellow-bg: #FFE757;
  --corn-bg: #F4B905;
  --blue-bg: #0000FF;
  --light-bg1: #FFFFF5;
  --light-bg2: #F6F5EF;
  --light-bg3: #FEFCFB;
  --light-bg4: #FFF7EA;
  --light-bg5: #FFFDF8;
  --light-bg6: #FFFCF2;
  --light-bg7: #F3EDE8;
  --light-bg8: #F8FCDD;
  --green-bg: #073D30;
  --green-bg2: #02854C;
  --cornflower-bg: #D68BE7;
}

$colors: (
 
);

$bodyfontScale: (
  xl: (
    20: 20px,
    lineheight: 32px,
  ),
  lg: (
    18: 18px,
    lineheight: 28px,
  ),
  md: (
    16: 16px,
    lineheight: 24px,
  ),
  xs: (
    14: 14px,
    lineheight: 22px,
  ),
  xss: (
    12: 12px,
    lineheight: 20px,
  ),
);

// button font size
$button_font_size: 16px;
$buton_line_height: 24px;
$buton_border_radius: 3px;
$button_padding: 0px 40px;
$button_font_weight: 700;
$button_font_family: $body-font;

