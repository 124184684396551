.aximo-content-thumb{
  position: relative;
  @include max(991px) {
    margin-bottom: 40px;
  }
}

.aximo-content{
  &-thumb{
    &.border-radius{
      img{
        border-radius: 20px;
      }
    }
    &-wrap{
      position: relative;
    }
  }
  
  &-thumb2{
    position: relative;
    border-radius: 300px 300px 0 0;
    overflow: hidden;
    border: 1px solid var(--heading-color);
    @include max(991px) {
      margin-bottom: 40px;
    }
  }
  
}

.aximo-contact-info{
  margin-top: 45px;
  h3{
    color: var(--light-color2);
    font-family: $heading-font3;
    margin-bottom: 30px;
  }
  ul{
    li{
      font-size: 21px;
      font-weight: 600;
      padding-left: 52px;
      position: relative;
      display: inline-block;
      margin-right: 24px;
      margin-bottom: 32px;
      color: var(--light-color2);
      &:last-child{
        margin: 0;
      }
      
      a{
        color: var(--light-color2);
        i{
          width: 40px;
          height: 40px;
          left: 0;
          top: -7px;
          display: flex;
          margin-right: 12px;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          position: absolute;
          color: var(--heading-color);
          background-color: var(--yellow-bg);
        }
      }
    }
  }
}