$breakpoints: (
  "xss": 575.98px,
  "xs": 767.98px,
  "md": 991.98px,
  "lg": 1199.98px,
  "xxl": 1399.98px,
  "xxl2": 1600px,
);

@mixin breakpoint($width, $type: max) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width;
    }
    @media (#{$type}-width: $width) {
      @content;
    }
  }
}


// Responsive media query

@mixin min($size) {
  @media (min-width: $size) {
    @content;
  }
}

@mixin max($size) {
  @media (max-width: $size) {
    @content;
  }
}

@mixin min-max($min, $max) {
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}
