/**!

    Template Name: 'Relik-Admin Dashboard'
    Template URI:
    Description:
    Author: 
    Author URI: 
    Version: 1.0.0
    -------------------------------------------------
     1.0 base css
     1.1 layout css
     1.2 components css
     1.3 pages css
     1.4 themes css
   
**/

// 1. Configuration and helpers
@import 
"abstracts/variables",
"abstracts/functions",
"abstracts/mixins",
"abstracts/placeholder";

// 2. Vendores
@import 
"vendors/bourbon/bourbon";

/*1.0 base css*/
@import 
"base/base", 
"base/fonts", 
"base/typography", 
"base/helpers";

// 4. Layout
/*1.1 layout css*/
@import 
"layout/header", 
"layout/footer";


// 5. Components
/*1.2 components css*/
@import  
"components/button", 
"components/form", 
"components/breadcrumbs", 
"components/pagination",
"components/hero",
"components/counter",
"components/team",
"components/iconbox",
"components/faq",
"components/video",
"components/testimonial",
"components/cta",
"components/social-icon",
"components/client",
"components/icon-list",
"components/pricing",
"components/shape",
"components/portfolio",
"components/table",
"components/account",
"components/section-title";

// 6. Page-specific styles
/*1.3 pages css*/
@import 
"pages/home2",
"pages/home3",
"pages/home4",
"pages/home5",
"pages/home6",
"pages/home7",

"pages/service",
"pages/pricing",
"pages/about",
"pages/blog",
"pages/contact",
"pages/preloader",
"pages/coming-soon",
"pages/portfolio";

// 7. Themes
/*1.4 themes css*/
@import "themes/dark";

//   body {
//     @include breakpoint(xxl2) {
//         background-color: magenta;
//     }
//     @include breakpoint(xxl) {
//         background-color: gold;
//     }
//     @include breakpoint(lg) {
//         background-color: purple;
//     }
//     @include breakpoint(md) {
//         background-color: red;
//     }
//     @include breakpoint(xs) {
//         background-color: yellow;
//     }
//     @include breakpoint(xs2) {
//         background-color: pink;
//     }
// }
