// bootstrap button style

.aximo-default-btn {
  font-size: $button_font_size;
  line-height: $buton_line_height;
  padding: $button_padding;
  border-radius: $buton_border_radius;
  font-weight: $button_font_weight;
  font-family: $button_font_family;
  width: fit-content;
  position: relative;
  z-index: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  text-align: center;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  overflow: hidden;
  color: var(--light-bg2)!important;
  background-color: var(--orange-bg);
  position: relative;
  z-index: 0;
  overflow: hidden;
  position:relative;
  height: 60px;
  line-height: 59px;
  transform-style: preserve-3d;
    .aximo-label-up {
      display:block;
      height:100%;
      position:relative;
      top:0%;
      transition:0.3s;
    }
    &:hover .aximo-label-up {
      top:-100%;
    }
    &.yellow-btn{
      color: var(--heading-color)!important;
      background-color: var(--yellow-bg);
    }
    &.blue-btn{
      color: var(--light-color3)!important;
      background-color: var(--blue-bg);
      &:hover{
        background-color: var(--orange-bg2);
      }
    }
    &.corn-btn{
      color: var(--green-color)!important;
        background-color: var(--corn-bg);
    }

    &.green-btn{
      color: var(--light-color5)!important;
      border: 2px solid var(--heading-color5);
      box-shadow: 0px 4px 0px 0px var(--heading-color5);
      background-color: var(--green-color2);
      &.shadow-white{
        box-shadow: 0px 4px 0px 0px var(--light-color5);
      }
      &:hover{
        box-shadow: none;
      }
    }
    &.blue-btn2{
      color: var(--light-color6)!important;
      background-color: var(--blue-bg);
      border: none;
      box-shadow: none;
    }
    

    &.aximo-header-btn{
      height: 50px;
      width: 150px;
      padding: 0;
      line-height: 47.5px;
      color: var(--heading-color)!important;
      border: 2px solid var(--heading-color);
      box-shadow: 0px 4px 0px 0px var(--white-smoke);
      background-color: var(--accent-bg);
      &:hover{
        box-shadow: none;
      }
      &.outline-btn{
        box-shadow: none;
        background-color: transparent;
        color: var(--heading-color2)!important;
        border: 2px solid var(--heading-color2);
      }
      &.yellow-btn{
        box-shadow: none;
        border: none;
        width: 180px;
        line-height: 50px;
        background-color: var(--yellow-bg);
      }
      &.green-btn{
        color: var(--light-color5)!important;
        border: 2px solid var(--heading-color5);
        box-shadow: 0px 4px 0px 0px var(--heading-color5);
        background-color: var(--green-color2);
        &:hover{
          box-shadow: none;
        }
      }
      &.barger-menu{
        width: 127px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          margin-right: 10px;
        }
      }
      &.corn-btn{
        box-shadow: none;
        border: none;
        width: 150px;
        line-height: 50px;
        color: var(--green-color)!important;
        background-color: var(--corn-bg);
      }
      &.blue-btn2{
        color: var(--light-color6)!important;
        background-color: var(--blue-bg);
        border: none;
        box-shadow: none;
      }
    }
    &.aximo-default-btn-outline{
      background-color: transparent;
      color: var(--heading-color2)!important;
      border: 2px solid var(--heading-color2);
      &.outline-white{
        color: var(--light-color3)!important;
        border: 2px solid var(--light-color3);
        // &:hover{
        //   background-color: var(--orange-bg2);
        //   border: 2px solid var(--orange-bg2);
        // }
      }
    }
  &.disabled,
  &:disabled {
    opacity: 1;
  }
  &.d-block {
    width: 100%;
  }
  &.pill {
    border-radius: 50px;
  }
  &.btn-icon {
    padding: 9px 24px;
  }
  &:focus {
    box-shadow: none;
  }
}



.aximo-icon-btn{
  color: var(--heading-color)!important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.14px;
  text-transform: uppercase;
  position: relative;
  font-family: $heading-font;
  padding-bottom: 8px;
  img{
    margin-left: 10px;
  }
  &::before, &::after{
    content: "";
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    position: absolute;
    transition-delay: 0s;
    background-color: var(--dark-bg);
    transition: -webkit-transform .75s cubic-bezier(.19,1,.22,1);
    transition: transform .75s cubic-bezier(.19,1,.22,1);
    transition: transform .75s cubic-bezier(.19,1,.22,1),-webkit-transform .75s cubic-bezier(.19,1,.22,1);
  }
}


a.aximo-icon-btn:before{
    transition-delay: 0s;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    transform-origin: left;
} 
a.aximo-icon-btn:after {
  transition-delay: .25s;
  -webkit-transform-origin: right;
  transform-origin: right;
}

a.aximo-icon-btn:hover:before{
  transition-delay: .25s;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

a.aximo-icon-btn:hover:after {
  transition-delay: 0s;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}



.aximo-btn-wrap{
  margin-top: 45px;
  @include max(991px) {
    margin-top: 30px;
    text-align: center;
  }
}