.aximo-main{
  &-form{
    padding: 30px;
    margin-right: 10px;
    border-radius: 30px;
    border: 1px solid var(--heading-color);
    @include max(991px) {
      margin-right: 0;
    }
  }
  &-field{
    margin-bottom: 22px;
    label{
      font-size: 16px;
      font-weight: 700;
      display: block;
      margin-bottom: 10px;
    }
  }
  
}

#aximo-main-btn{
  width: 180px;
  height: 60px;
  display: flex;
  color: #FFFDF8;
  font-size: 16px;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  transition: all 0.4s;
  background-color: var(--dark-bg);
  &:hover{
    color: var(--heading-color);
    background-color: var(--accent-bg);
  }
}

.aximo-contact-thumb{
  border-radius: 17px;
  overflow: hidden;
  margin-left: 40px;
  border: 7px solid var(--heading-color);
  @include max(991px) {
    margin-left: 0;
    margin-bottom: 40px;
  }
}

.aximo-contact-info{
  &-section{
    padding-bottom: 36px;
  }
  &-title{
    text-align: center;
    margin-bottom: 50px;
  }
  &-box{
    display: flex;
    padding: 30px 70px;
    border-radius: 10px;
    margin-bottom: 24px;
    background-color: var(--dark-bg);
    box-shadow: 0px 4px 80px 0px rgba(0, 0, 0, 0.08);
    @include max(1399px) {
      padding: 30px;
    }
  }
  &-icon{
    flex-shrink: 0;
    margin-right: 24px;
  }
  &-data{
    color: var(--white-smoke);
    span{
      font-size: 24px;
      font-weight: 600;
      display: block;
      margin-bottom: 10px;
    }
    p{
      font-size: 21px;
      margin-bottom: 0;
    }
  }
}

.aximo-map-wrap {
  padding-bottom: 130px;
  border-bottom: 1px solid #E6E6E6;
  @include max(991px) {
    padding-bottom: 100px;
  }
  @include max(767) {
    padding-bottom: 70px;
  }
}
#map{
  width: 100%;
  height: 590px;
  border-radius:  30px;
  border: 5px solid var(--dark-bg);
}